import React from 'react';
import { Navbar, Nav, Container } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import './Navbar.css';

const MyNavbar = () => {
    return (
        <Navbar expand="lg">
            <Container>
                <Navbar.Brand>GratiTune</Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="me-auto">
                        <NavLink exact to="/" className={({ isActive }) => isActive ? "nav-link active" : "nav-link"}>Calendar</NavLink>
                        <NavLink to="/questions" className={({ isActive }) => isActive ? "nav-link active" : "nav-link"}>My Questions</NavLink>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
};

export default MyNavbar;
