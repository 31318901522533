import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css'; // Standard react-calendar style
import './Calendar.css'; // Custom style file for Calendar

const CalendarComponent = () => {
    const [date, setDate] = useState(new Date());
    const navigate = useNavigate();

    const onChange = (newDate) => {
        setDate(newDate);
        const localDate = new Date(newDate.getTime() - (newDate.getTimezoneOffset() * 60000));
        navigate(`/journal/${localDate.toISOString().split('T')[0]}`);
    };

    return (
        <div className="container calendar-container">
            <div className="row">
                <div className="col">
                    <Calendar
                        onChange={onChange}
                        value={date}
                        tileClassName={({ date, view }) => {
                            // Highlight the current day
                            if (date.getDate() === new Date().getDate() &&
                                date.getMonth() === new Date().getMonth() &&
                                date.getFullYear() === new Date().getFullYear()) {
                                return 'highlight';
                            }
                        }}
                    />
                </div>
            </div>
        </div>
    );
};

export default CalendarComponent;
