import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './JournalPage.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, Form, Button, Table } from 'react-bootstrap';
import { useParams } from 'react-router-dom';

const JournalPage = () => {
    const [questions, setQuestions] = useState([]);
    const [answers, setAnswers] = useState({});
    const [entry, setEntry] = useState(null); // To store the fetched journal entry
    const { date } = useParams();

    useEffect(() => {
        const fetchEntry = async () => {
            try {
                const res = await axios.get(`http://localhost:3001/journal/${date}`);
                console.log(res.data); // Add this line to log the response data
                setEntry(res.data);
            } catch (error) {
                console.error('Error fetching journal entry', error);
            }
        };
        fetchEntry();
    }, [date]);

    const formatDate = (dateString) => {
        const days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
        const date = new Date(dateString);
        const dayName = days[date.getDay()];
        const day = date.getDate();
        const year = date.getFullYear();

        const ordinalSuffix = (day) => {
            const j = day % 10, k = day % 100;
            if (j === 1 && k !== 11) {
                return "st";
            }
            if (j === 2 && k !== 12) {
                return "nd";
            }
            if (j === 3 && k !== 13) {
                return "rd";
            }
            return "th";
        };

        return `${dayName} the ${day}${ordinalSuffix(day)} (${year})`;
    };

    const handleGenerateQuestions = async () => {
        try {
            const res = await axios.get('http://localhost:3001/questions/random');
            setQuestions(res.data);
            console.log(res.data);
        } catch (error) {
            console.error('There was an error fetching the questions!', error);
        }
    };

    const handleAnswerChange = (id, answer) => {
        setAnswers(prevAnswers => ({ ...prevAnswers, [id]: answer }));
    };

    // Check if all questions have answers and none of the answers is an empty string
    const isSubmitDisabled = questions.length === 0 || questions.some(question => !answers[question.id] || answers[question.id].trim() === '');

    const handleSubmitAnswers = async () => {
        try {
            const userId = 1;
            const entryDate = date; // The date from your useParams
            const answersArray = Object.entries(answers).map(([id, answer]) => ({ id, answer }));

            // Make sure you have exactly three answers before sending
            if (answersArray.length === 3) {
                await axios.post('http://localhost:3001/answers', {
                    userId,
                    entryDate,
                    answers: answersArray
                });
                console.log('Answers submitted successfully');

                // Reload the page to display the submitted answers
                window.location.reload();
            } else {
                console.error('You must fill out all answers before submitting.');
            }
        } catch (error) {
            console.error('There was an error submitting the answers!', error);
        }
    };

    if (entry) {
        return (
            <Container className='questionsContainer'>
                <h2 className="custom-title text-white journal-title">Gratitude Journal - {formatDate(date)}</h2>
                <Table striped bordered hover variant="dark" className="rounded-table">
                    <tbody>
                        {entry.map((item, index) => (
                            <tr key={index}>
                                <td>{item.question}</td>
                                <td>{item.answer}</td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            </Container>
        );
    }

    return (
        <Container className='questionsContainer'>
            <div className="d-flex justify-content-between align-items-center mb-4">
                <h2 className="custom-title text-white journal-title">Gratitude Journal - {formatDate(date)}</h2>
                <Button onClick={handleGenerateQuestions}>Generate Questions</Button>
            </div>

            {questions.length === 0 ? (
                // Placeholder for empty questions
                <div className="text-center text-muted">
                    <p className='generate-questions-info'>No questions generated yet. Click "Generate Questions" to start.</p>
                </div>
            ) : (
                // Table of questions
                <Table striped bordered hover variant="dark" className="rounded-table">
                    <tbody>
                        {questions.map(question => (
                            <tr key={question.id}>
                                <td>{question.question}</td>
                                <td>
                                    <Form.Control
                                        type="text"
                                        value={answers[question.id] || ''}
                                        onChange={e => handleAnswerChange(question.id, e.target.value)}
                                    />
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            )}

            <div className="d-flex justify-content-end mt-4">
                <Button variant="success" type="submit" disabled={isSubmitDisabled} onClick={handleSubmitAnswers}>
                    Submit Answers
                </Button>
            </div>
        </Container>
    );
};

export default JournalPage;