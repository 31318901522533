import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Modal, Button, Form } from 'react-bootstrap';
import './QuestionsPage.css';

const QuestionsPage = () => {
    const [questions, setQuestions] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [showDeleteConfirmModal, setShowDeleteConfirmModal] = useState(false);
    const [newQuestion, setNewQuestion] = useState('');
    const [questionToDelete, setQuestionToDelete] = useState(null);

    useEffect(() => {
        axios.get('http://localhost:3001/questions')
            .then(response => {
                setQuestions(response.data);
                console.log('Questions on page load:', response.data); // Log the questions
            })
            .catch(error => {
                console.error('There was an error fetching the questions!', error);
            });
    }, []);

    const handleModalClose = () => setShowModal(false);
    const handleModalShow = () => setShowModal(true);
    const handleQuestionChange = (e) => setNewQuestion(e.target.value);

    const submitNewQuestion = () => {
        axios.post('http://localhost:3001/questions', { question: newQuestion })
            .then(response => {
                setQuestions([...questions, response.data]);
                setNewQuestion('');
                handleModalClose();
            })
            .catch(error => {
                console.error('There was an error posting the question!', error);
            });
    };

    const handleDeleteClick = (id) => {
        setShowDeleteConfirmModal(true);
        setQuestionToDelete(id);
    };

    const deleteQuestion = () => {
        axios.delete(`http://localhost:3001/questions/${questionToDelete}`)
            .then(() => {
                setQuestions(questions.filter(question => question.id !== questionToDelete));
                setShowDeleteConfirmModal(false);
            })
            .catch(error => {
                console.error('There was an error deleting the question!', error);
            });
    };

    const darkModalStyle = {
        backgroundColor: '#343a40', // Dark background for the modal
        color: 'white' // Light text color
    };


    return (
        <div className="container mt-5">
            <div className="d-flex justify-content-between align-items-center mb-4">
                <h1 className="text-white custom-title">My Questions</h1>
                <Button variant="primary" onClick={handleModalShow}>
                    Add Question
                </Button>
            </div>
            <ul className="list-group">
                {questions.map(question => (
                    <li key={question.id} className="list-group-item d-flex justify-content-between align-items-center" style={{ backgroundColor: '#212529', color: 'white', borderColor: '#32383e' }}>
                        {question.question}
                        <Button variant="danger" onClick={() => handleDeleteClick(question.id)}>
                            Delete
                        </Button>
                    </li>
                ))}
            </ul>

            {/* Add Question Modal */}
            <Modal show={showModal} onHide={handleModalClose}>
                <Modal.Header closeButton style={darkModalStyle} className="custom-modal-header">
                    <Modal.Title>Add a New Question</Modal.Title>
                </Modal.Header>
                <Modal.Body style={darkModalStyle}>
                    <Form>
                        <Form.Group>
                            <Form.Control type="text" placeholder="Enter your question" value={newQuestion} onChange={handleQuestionChange} className="white-placeholder" style={{ backgroundColor: '#495057', color: 'white' }} />
                        </Form.Group>

                    </Form>
                </Modal.Body>
                <Modal.Footer style={darkModalStyle}>
                    <Button variant="primary" onClick={submitNewQuestion}>
                        Save Question
                    </Button>
                </Modal.Footer>
            </Modal>

            {/* Delete Confirmation Modal */}
            <Modal show={showDeleteConfirmModal} onHide={() => setShowDeleteConfirmModal(false)}>
                <Modal.Header closeButton style={darkModalStyle}>
                    <Modal.Title>Confirm Deletion</Modal.Title>
                </Modal.Header>
                <Modal.Body style={darkModalStyle}>
                    Are you sure you want to delete this question?
                </Modal.Body>
                <Modal.Footer style={darkModalStyle}>
                    <Button variant="danger" onClick={deleteQuestion}>
                        Delete
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default QuestionsPage;
