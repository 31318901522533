import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import Calendar from './components/Calendar';
import JournalPage from './components/JournalPage'; // Import the new component
import QuestionsPage from './components/QuestionsPage'; // Add this import
import './App.css';

function App() {
  return (
    <Router>
      <div className="App">
        <Navbar />
        <div className="main-content">
          <Routes>
            <Route path="/" element={<Calendar />} />
            <Route path="/journal/:date" element={<JournalPage />} />
            <Route path="/questions" element={<QuestionsPage />} /> {/* Add this line */}
          </Routes>
        </div>
        <Footer />
      </div>
    </Router>
  );
}


export default App;
